import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-penetration4050-page',
  templateUrl: './penetration4050-page.component.html',
  styleUrls: ['./penetration4050-page.component.scss']
})
export class Penetration4050PageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
