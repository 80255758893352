import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.scss']
})

export class GalleryPageComponent implements OnInit {
  // get specific layers from html as 'initial elements' to access and modify them
  // it works instead document.querySelector
  /* ====================== */
  /*    COLLECT ELEMENTS    */
  /* ====================== */
  @ViewChild('lightboxWrapper', {static: false}) allImagesHolder: ElementRef;
  @ViewChild('lightbox', {static: false}) entireLightboxLayer: ElementRef;
  @ViewChild('imageWrapper', {static: false}) importedMainImageTag: ElementRef;

  constructor() { }

  sourceUrl = '../../../../assets/images/';
  // sourceUrl = "../../../../lgyp/assets/images/";

  images = [
    {title: 'lgyp', src: this.sourceUrl + '1.jpg'},
    {title: 'lgyp', src: this.sourceUrl + '2.jpg'},
    {title: 'lgyp', src: this.sourceUrl + '3.jpg'},
    {title: 'lgyp', src: this.sourceUrl + '4.jpg'},
    {title: 'lgyp', src: this.sourceUrl + '5.jpg'},
    {title: 'lgyp', src: this.sourceUrl + '6.jpg'},
    {title: 'lgyp', src: this.sourceUrl + '7.jpg'},
    {title: 'lgyp', src: this.sourceUrl + 'lgypneww.jpg'},
    {title: 'lgyp', src: this.sourceUrl + 'AWYF8894.jpg'},
    {title: 'lgyp', src: this.sourceUrl + 'BACD5886.jpg'},
    {title: 'lgyp', src: this.sourceUrl + 'IFEB1928.jpg'},
    {title: 'lgyp', src: this.sourceUrl + 'IMG_4228.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'IMG_4233.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'IMG_4276.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'IMG_4593.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'IMG_4594.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'IMG_4676.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'IMG_20190827_095038.jpg'},
    {title: 'lgyp', src: this.sourceUrl + 'IMG_20190827_095648.jpg'},
    {title: 'lgyp', src: this.sourceUrl + 'JEEI4111.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'KNBQ605cd.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'LYYS9121.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'MSRS4794.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'MZWF9376.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'NACS0488.JPG'},
    {title: 'lgyp', src: this.sourceUrl + 'PHOTO-2019-09-06-19-09-55.jpg'},
    {title: 'lgyp', src: this.sourceUrl + 'PHOTO-2019-09-08-16-02-00.jpg'},
    {title: 'lgyp', src: this.sourceUrl + 'PHOTO-2019-09-08-16-02-10.jpg'},
    {title: 'lgyp', src: this.sourceUrl + 'YBOT3917.JPG'},
  ];

  ngOnInit() {
    window.addEventListener('resize', this.resizeAction);
  }


  /* ====================== */
  /*    DEFINE FUNCTIONS    */
  /* ====================== */
  ratio = (x, y) => {
    return x / y;
  }

  sizeEditor = (screenWidth, screenHeight, elementToShow) => {
    let windowRatio = this.ratio(screenWidth, screenHeight);
    let imgRatio = this.ratio(elementToShow.width, elementToShow.height);
    let mainImageTag = this.importedMainImageTag.nativeElement;
    // console.log(mainImageTag);

    let newSize;
    if (windowRatio > 1 ) {
      if (imgRatio > 1) {
        newSize = screenWidth * (60 / 100);
        mainImageTag.style.width = newSize + 'px';
        mainImageTag.style.height = 'auto';
        // console.log(mainImageTag);
      } else if (imgRatio < 1) {
        newSize = screenHeight * (60 / 100);
        mainImageTag.style.height = newSize + 'px';
        mainImageTag.style.width = 'auto';
        // console.log(mainImageTag);
      } else {
        newSize = screenHeight * (60 / 100);
        mainImageTag.style.height = newSize + 'px';
        mainImageTag.style.width = 'auto';
        // console.log(mainImageTag);
      }
    } else if (windowRatio < 1) {
      if (imgRatio > 1) {
        newSize = screenWidth * (70 / 100);
        mainImageTag.style.width = newSize + 'px';
        mainImageTag.style.height = 'auto';
        // console.log(mainImageTag);
      } else if (imgRatio < 1) {
        newSize = screenHeight * (60 / 100);
        mainImageTag.style.height = newSize + 'px';
        mainImageTag.style.width = 'auto';
        // console.log(mainImageTag);
      } else {
        newSize = screenHeight * (60 / 100);
        mainImageTag.style.height = newSize + 'px';
        mainImageTag.style.width = 'auto';
        // console.log(mainImageTag);
      }
    }
  }

  goNext = () => {
    let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const mainImageTag = this.importedMainImageTag.nativeElement;
    const imagesObject = this.allImagesHolder.nativeElement.children;
    const allImages = [];
    Array.from(imagesObject).forEach((div) => allImages.push(div));
    let currentIndex = allImages.findIndex(
      (item) => item.firstChild.src as HTMLImageElement === mainImageTag.src
    );
    if (currentIndex < allImages.length - 1) {
      currentIndex++;
    } else {
      currentIndex = 0;
    }
    mainImageTag.src = allImages[currentIndex].firstChild.src;
    const currentImageElement = allImages[currentIndex].firstChild;
    this.sizeEditor(screenWidth, screenHeight, currentImageElement);
  }

  goBack = () => {
    let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const mainImageTag = this.importedMainImageTag.nativeElement;
    const imagesObject = this.allImagesHolder.nativeElement.children;
    const allImages = [];
    Array.from(imagesObject).forEach((div) => allImages.push(div));
    let currentIndex = allImages.findIndex(
      (item) => item.firstChild.src as HTMLImageElement === mainImageTag.src
    );
    if (currentIndex > 0) {
      currentIndex--;
    } else {
      currentIndex = allImages.length - 1;
    }
    mainImageTag.src = allImages[currentIndex].firstChild.src;
    const currentImageElement = allImages[currentIndex].firstChild;
    this.sizeEditor(screenWidth, screenHeight, currentImageElement);
  }

  showOriginalPhoto = (e) => {
    let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    this.sizeEditor(screenWidth, screenHeight, e.target);
    const lightbox = this.entireLightboxLayer.nativeElement;
    const mainImageTag = this.importedMainImageTag.nativeElement;
    lightbox.style.display = 'block';
    mainImageTag.src = e.target.src;
  }

  closeButton = (el) => {
    el.target.parentElement.style.display = 'none';
  }

  resizePerformance;
  resizeAction = () => {
    //prevent current setTimeout()
    clearTimeout(this.resizePerformance);
    // do setTimeout inside a variable to can reset it by calling
    this.resizePerformance = setTimeout(() => {
      let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const mainImageTag = this.importedMainImageTag.nativeElement;
      const imagesObject = this.allImagesHolder.nativeElement.children;
      const allImages = [];
      Array.from(imagesObject).forEach((div) => allImages.push(div));
      let currentIndex = allImages.findIndex(
        (item) => item.firstChild.src as HTMLImageElement === mainImageTag.src
      );
      const currentImageElement = allImages[currentIndex].firstChild;
      this.sizeEditor(screenWidth, screenHeight, currentImageElement)
    }, 500)
  }


  /* ====================== */
  /*       REACTIONS        */
  /* ====================== */
  // all reactions in Angular, handeled either by html file with inline events like (click) or
  // by ngOnInit inside component.ts
}  