import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vision-page',
  templateUrl: './vision-page.component.html',
  styleUrls: ['./vision-page.component.scss']
})
export class VisionPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
