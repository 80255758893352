import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit, OnDestroy {

  searchedPhrase: string;
  resultCounter;

  searchingSubscription: Subscription;

  dataset = [
    {
      title: 'About Us',
      description: 'We are expert is supplying Gypsum Rock and Gypsum Powder and Crystal Gypsum. Laira Gypsum , established in 2008, forming part of the Gypsum activities within the construction products sector such as supplying Natural Gypsum (Gypsum rock) with high purity (above 90%) to Cement and Gypsum factories. We supply the high quality natural gypsum to Asian countries such as Malaysia, Singapore, Indonesia, India, Taiwan, China and etc. We also supply high quality Gypsum Powder (Plaster of Paris) for wall coating and decoration, Gypsum Board production, Gypsum Ceiling tiles, Stopping compound, Joint compound and etc. We also supply Crystal Gypsum which is 100% pure to provide solution for food and medical industries. We provide solution for other industries such as Agriculture and Sculpturing. We serve the growing market of Malaysia, Singapore, Brunei, India, Indonesia, China and many other countries, offering a complete range of solution for all aesthetic design, acoustic requirements, fire performance and duty rating.',
      content: 'Quality Policy Our Quality Policy covers the full range of our activities including, our product and process certification program, our management system certification programs, and our training center. Our Quality Policy emphasizes the following key issues: Providing unbiased and impartial services. Providing services and the highest level of accuracy and reliability. Providing services courteously and politely. Providing efficient service. Providing service at a fair price. Providingservices by a professional team that is trained and qualified for the job. Providing technical audits by auditors with appropriate professional backgrounds. Maintaining strict confidentiality. Maintaining strict adherence to professional and personal ethical rules. We perform regular risk assessments of our certification operations, and we are insured to cover these risks. We report on our financial stability and income sources to the Board of Director’s Quality and Certification Committee on a regular basis.',
      url: '/about/policy'
    },
    {
      title: 'VISION AND MISSION',
      description: '',
      content: 'Our Vision To be a leading corporate in the supplying Natural Gypsum & Gypsum Powder, as Raw Material and products in Construction, Agriculture, and Chemical Industries. Mission To provide the best quality product for our valued customers. We are committed to supply, only the best quality and higher purity Gypsum Powder as only the top grade highest gypsum powder will be used to produce; Casting, Cornices, and stopping plaster. We aim to strengthen our commitment towards our clients as we believe in initiating and continuing transparent and supportive relationship with our clients and trading partners will lead us to our further aim which is to contribute to the development of the building material industry in order to achieve a clear vision that can strengthen our structure and enrich the building experience of end-users.',
      url: '/about/vision'
    },
    {
      title: 'CRYSTAL GYPSUM',
      description: 'We supply Crystal Gypsum which is 99.99% pure to provide solution for food and medical industries. Our company is ranked among reliable Exporters and Suppliers of superior-grade CRYSTAL GYPSUM which is 100% pure. Crystal Gypsum is mostly used in food and medical industry. We make it available in bulk in chemical-grade packaging. Interested buyers can obtain it from us at competitive prices.',
      content: 'Package 1 metric ton Jumbo Bag 1.2 Metric ton Jumbo Bag 1.5 metric ton Jumbo Bag',
      url: '/products/crystal'
    },
    {
      title: 'GYPSUM PWODER',
      description: 'We supply best quality GYPSUM POWDER with finest and high whiteness qualities from sophisticated screening technology for higher purity of Gypsum Powder. We assure customers, our GYPSUM POWDER is made under controlled environments to ensure purity and zero moisture. We make it available in two sizes: Mesh 100 and Mesh 200. All of our Gypsum Powder production size are uniform and 100% guaranteed. We can also provide various packing and make it either plain or branded bags according to our client’s requests. Buy superior-grade Gypsum Powder at market leading prices. Our company is ranked among budding Exporters and Suppliers of Gypsum Powder ',
      content: 'Applications: Use to manufacture gypsum panel or building plaster Use to manufacture Gypsum Board, suspended ceiling, plaster ceiling, cornices,... etc Decorative coating for walls Use to make gypsum mould or statutes As grout of marble, clay and ceiling tiles As a colour additive Act as additive to other products Advantages: Adjustable setting time according to customer’s needs and requirement. Super strength after drying due to the use of highly pure gypsum rock. High resistance to fire and water. Whiteness above 92% It can easily be moulded into any shapes Ultra white & Ultra Smooth Low moisture Absorption Type: Super micronized gypsum powder Façade rendering gypsum Packaging: 20 Kg Bag 25 Kg Bag 30 Kg Bag 50 Kg Bag 1 ton jumbo bag 1.2 ton jumbo bag 1.5 ton jumbo bag ',
      url: '/products/powder'
    },
    {
      title: 'GYPSUM ROCK',
      description: 'Laira Gypsum is ranked among reliable Exporters and Suppliers of superior-grade Gypsum Rock to cement and Gypsum factories. Extensively used as a raw material for manufacturing fertilizers, paints, construction materials and much more, the Gypsum Rock we make available is pure, and compositionally balanced. We make it available in bulk in chemical-grade packaging. Interested buyers can obtain it from us at competitive prices. We produce sized natural Gypsum with purity MIN 90% percent that generally usage in cement industry and Gypsum factories.',
      content: 'USAGE: Use to manufacturer gypsum panel and building plaster Use for Gypsum Boards and ceiling boards Use to make Gypsum Powder (Plaster of Paris) Use to manufacturer cement Use as fertilizer Use by chemical factories Product Description Gypsum or CA SO4.2H2O., a mineral substance that is found in nature in the form of rocky and mostly open cast layers. Gypsum is known as hydrous calcium sulfate and is considered to be one of the most valuable building materials and minerals. Product Benefits: Expertise and experience more than half a century in production Ability to provide FOB, CT F and DAP Flexibility in payment (Flexibility in how payment i made) Stable quality Delivery and timely fulfillment of obligations Production according to customer’s request for any size distribution Transparency-Trust-Commitment Product Applications Product Applications: This type of gypsum is used for final layer of plastering with high mesh. In addition, it is used in medicine, sculpture, decoration, GYPSUM BOARD factories and gypsum prefabricated walls.',
      url: '/products/rock'
    },
    {
      title: 'PLASTER GYPSUM MESH 100 SPECIFICATION SUPER MICRONIZED 100 MESH',
      description: 'This product is specially for first coat and a first layer of gypsum plaster. The difference of this gypsum with the previous product (gypsum and clay mixture) is that in areas where high humidity, it is recommended and necessary to use this type of gypsum that is pure with high strength.',
      content: 'Product Benefits Adjustable setting time depending to the customers and market needs Suitable size distribution for plaster usage super strength after drying Due to the use of pure natural gypsum High resistance to fire and water No cracking after drying Because of its consistent sitting Whiteness above 95% Product Applications This type of gypsum is specially designed for the first layer of wall and ceiling, clearing, bricks intrados, barrel arches and etc. Specifictation FEATURE VALUE Whiteness (%) Above 90% Initial Setting Time(minute) 6 – 8 Min (Adjustable) Final Setting Time (Minute) 12 -16 Min (Adjustable) Finesse (MESH)	100 MESH Flexural Strenght (Mpa) 3.90 Mpa and more Surface Hardness (Mpa) 1.50 to up Parameters Unit Acceptance Limit Results Test Method Whiteness 87up 92% C471-M-14 Water Stucco Ratio 75% Compre ssive Strength 6up 9.90N/mm2 Flexural Strength 2up 3.90N/mm2 Fineness 100 mesh sive pass:>96 6% CW 4-7 6% Slump 155-210	180 Surface Hardness 1.50N/mm2',
      url: '/products/powder/100'
    },
    {
      title: 'PLASTER GYPSUM MESH 200 SPECIFICATION SUPER MICRONIZED 200 MESH',
      description: 'Plaster gypsum mesh 200 is produced with special size distribution and physical – chemical properties, according to international standards. This product is very soft and has excellent flexibility. ',
      content: 'Product Benefits Whiteness and shininess above 90% Product Micronization that causes the final layer of plaster to be flat and polished Remarkable flexibility and softness Great adhesion Continuous wash ability No cracking due to high purity Late Initial setting time and fast Final setting time Product Applications This type of gypsum is used for final layer of plastering with high mesh. In addition, it is used in MOLDING, medicine, sculpture, decoration, GYPSUM BOARD factories and gypsum prefabricated walls. Product Information: GYPSYM POWDER – MESH 20 Specifictation FEATURE VALUE Whiteness Above 90% Initial Setting Time(minute) 6 – 8 Min (Adjustable) Final Setting Time (Minute) 12 -16 Min (Adjustable) Finesse (MESH) 200 MESH exural Strength (After 3 hours) Mpa 2.65 Mpa and more Surface Hardness (Mpa) 1.50 to up Parameters Unit Acceptance Limit Results Test Method Whiteness 87up 92% C471-M-1 Water Stucco Ratio 75% Compre ssive Strength 6up 12.10N/mm2 Flexural Strength 2.7up 4.50N/mm2 Fineness 200 mesh sive pass:>96 0.4% CW 4-7 6.2% Surface Hardness 1.50N/mm2',
      url: '/products/powder/200'
    },
    {
      title: 'LOGESTIC',
      description: 'Laira Group providing best LOGISTIC AND LAND TRANSPORTATION SERVICES in the port. With our updated exclusive land transport fleet, we are trouble free from restrictions that enable us to meet on-time delivery to the berthing facilities at port We maintain and improve our smooth and timely operations to meet our customer’s satisfaction aside from the fact that we are putting our commitment to them on highest regard. Thus, we assure our clients to provide the best services as we have developed our operation whom are very well trained and experienced. ',
      content: '',
      url: '/services/logestic'
    },
    {
      title: 'PORT FACILITIES',
      description: 'We are providing suitable PORT FACILITIES as per our customers’ requirements with DEDICATED STOCK YARD for the storage of different materials and exclusive SHIPPING AND FORWARDING AGENCY for easy carrying out of relevant works particularly PORT HANDLING, CONTAINER HANDLING AND STUFFING, LOADING, DISCHARGING, AND CUSTOMERS’ CLEARING OPERATIONS WITH ALL CUSTOMS FORMALITIES. Efficient equipment are used by the company in handling its cargos for customer satisfaction.',
      content: '',
      url: '/services/port'
    },
    {
      title: 'MARIN TRANSPORT OPERATION',
      description: 'Our company has built up very tight and close relationship with the most reliable vessel owners / vessel brokers and shipping agencies. We have more than 10 years of experience in delivering Bulk and Packed cargos in Barges, Vessels and Containers. We assist our clients to get the best shipping services with the most competitive prices to make sure their end cost is very reasonable and competitive. ',
      content: '',
      url: '/services/marin'
    },
    {
      title: 'downloads',
      description: '',
      content: 'Gypsum Powder Mesh 200 Gypsum Powder Mesh 100 Gypsum Rock SPEC',
      url: '/downloads'
    },
    {
      title: 'Contact Info',
      description: '',
      content: 'message Address: B2-2, Jalan Selaman 1, Dataran Palma, 68000 Ampang, Selangor Telephone: +603 4270 5578 Mobile & Whatsapp: +60163411952 Email: info@lairagypsum.com ',
      url: '/contact'
    },
  ];


  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.searchingSubscription = this.route.queryParams.subscribe(
      (res) => {
        this.searchedPhrase = res.chkd;
        this.resultCounter = res.rs; //to reset resultCounter
      }
    );
    // console.log(this.searchedPhrase);
  }

  ngOnDestroy(): void {
    this.searchingSubscription.unsubscribe();
  }

  getResultCount(i) {
    this.resultCounter = i + 1;
  }

  // ngAfterViewInit() {
  //   this.resultCounter.subscribe(
  //   )
  // }

  // trackByFn(index:number, item:any): any {
  //   // return movie.id;
  //   console.log(item);
  // }
}
