import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-penetration85100-page',
  templateUrl: './penetration85100-page.component.html',
  styleUrls: ['./penetration85100-page.component.scss']
})
export class Penetration85100PageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
