import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})

export class SearchFilterPipe implements PipeTransform {

  transform(datasetArray: any, fstring: string, prop: string): any {
    if (datasetArray.length === 0 || fstring === '') {
      return datasetArray;
    }
    const tempArray = [];
    fstring = fstring.toLowerCase();
    // console.log(value.length);
    for (let data of datasetArray) {
      if (data.title.toLowerCase().search(fstring) != -1 || data.description.toLowerCase().search(fstring) != -1 || data.content.toLowerCase().search(fstring) != -1) {
        tempArray.push(data);
      }
    }
    return tempArray;
  }
}
