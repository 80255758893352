import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import {Location} from "@angular/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  /* ====================== */
  /*    COLLECT ELEMENTS    */
  /* ====================== */
  @ViewChild('languages', {static: false}) languageSelection: ElementRef;

  inputElement: string;
  enLanguage: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute) {
    router.events.subscribe( () => {
      const y = document.getElementById('dropdownSearch');
      const r = document.getElementById('menuWrapper');

      if (y.className === 'dropdown-search opened') {
        y.className = 'dropdown-search';
      }

      if (r.className === 'menu-wrapper responsive') {
        r.className = 'menu-wrapper';
      }
    });
  }

  inputData(event: Event) {
    // console.log(event);
    // this.inputElement = (event.target as HTMLInputElement).value;
  }

  searchClick() {
    this.router.navigate(['/search', 'result'], {queryParams: {chkd: this.inputElement, rs: '0'}, fragment: 'lgyp'});
    // this.router.navigate(['search', 'result'], {queryParams: {chkd: this.inputElement, rs: '0'}, fragment: 'lgyp'});
  }

  cl() {
    this.router.navigate(['search', 'result/'], {queryParams: {chkd: this.inputElement, rs: '0'}, fragment: 'lgyp'});
  }

  cl2() {
    this.router.navigate(['lgyp/search', 'result']);
  }

  cl3() {
    this.router.navigate(['/lgyp/search', 'result']);
  }

  cl4() {
    this.router.navigate(['../../lgyp/search', 'result']);
  }

  languagesBtn() {
    let langDropdown = this.languageSelection.nativeElement.style.display;
    if (langDropdown == '' || langDropdown === 'none') {
      this.languageSelection.nativeElement.style.display = 'block';
    } else {
      this.languageSelection.nativeElement.style.display = 'none';
    }
  }

  lunchEnglishVersion() {
    // this.router.navigate(['/']);
    window.location.href = "http://www.lairagypsum.com";
  }

  lunchChineesVersion() {
    // this.router.navigate(['/zh']);
    window.location.replace("http://www.lairagypsum.com/ch");
  }


  ngOnInit() {
    // console.log(this.router.url);
    // const languageVersion = this.route;
    
    // this.route.params.subscribe(
    //   (params: Params) => {
    //     console.log(params);
    //   }
    // )

    // console.log(this.location.path);
    // console.log('path' + this.location.path());
    // console.log(this.location.subscribe(
    //   (a) => {console.log(a)}
    // ));

    const getIcon = document.getElementById('responsiveIcon');
    getIcon.addEventListener('click', () => {
      const x = document.getElementById('menuWrapper');
      if (x.className === 'menu-wrapper') {
        x.className += ' responsive';
      } else {
        x.className = 'menu-wrapper';
      }
    });
  
    document.getElementById('searchIcon').addEventListener('click', () => {
      const x = document.getElementById('dropdownSearch');
      if (x.className === 'dropdown-search') {
        x.className += ' opened';
      } else {
        x.className = 'dropdown-search';
      }
    });

    // for support rotate situation from mobile vertical to horizontal, needs to define window.resize
    let w: number = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const resp = 769;

    if (w < resp) {
      const dropdownList = document.getElementsByClassName('parent-list');
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < dropdownList.length; i++) {
        dropdownList[i].addEventListener('click', function() {
          let dropdownSituation = this.children[1].style.visibility;
          if (dropdownSituation === '' || dropdownSituation === 'hidden') {
            this.children[1].style.visibility = 'visible';
            this.children[1].style.opacity = 1;
          } else {
            this.children[1].style.visibility = 'hidden';
            this.children[1].style.opacity = 0;
          }
        });
      }
    }

    // When the user scrolls the page, execute myFunction
    window.onscroll = () => {
      stickyAction();
    };

    // Get the navbar
    var stickyMenu = document.getElementById('topNavbarMenu');

    // Get the offset position of the navbar
    var sticky = stickyMenu.offsetTop;

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function stickyAction() {
      if (window.pageYOffset >= sticky) {
        stickyMenu.classList.add('sticky');
      } else {
        stickyMenu.classList.remove('sticky');
      }
    }
  }
}
