import { Component, OnInit } from '@angular/core';
// import { testmodule } from "../../testfile";

// testmodule.testMethod();

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // const modal = document.getElementById('imgModal');
    // const screenHeight = window.innerHeight;
    // const screenWidth = window.innerWidth;
    // // Get the image and insert it inside the modal - use its "alt" text as a caption
    // const img = document.getElementById('imgThumb') as HTMLImageElement;
    // const imgWidth = img.clientWidth;
    // const imgHeight = img.clientHeight;
    // const aspectRatio = imgWidth / imgHeight;
    // const modalImg = document.getElementById('imgPlacement') as HTMLImageElement;
    // const captionText = document.getElementById('caption');
    // // tslint:disable-next-line: only-arrow-functions
    // img.addEventListener('click', function() {
    //   modal.style.display = 'block';
    //   modalImg.src = this.src;
    //   const screenDirection = screenWidth / screenHeight;
    //   // check horizontal or vertical
    //   if (screenDirection < 0) {
    //     modalImg.width = screenWidth * 0.8;
    //     console.log(modalImg.width);
    //     modalImg.style.height = modalImg.width * aspectRatio + 'px';
    //     console.log(modalImg.style.height);
    //     captionText.innerHTML = this.alt;
    //   } else {
    //     modalImg.height = screenHeight * 0.7;
    //     console.log(aspectRatio);
    //     console.log(modalImg.height);
    //     modalImg.style.width = modalImg.height * aspectRatio + 'px';
    //     console.log(modalImg.style.width);
    //     captionText.innerHTML = this.alt;
    //   }
    // }, true);

    // // Get the <span> element that closes the modal
    // const span = document.getElementsByClassName('close')[0] as HTMLImageElement;

    // // When the user clicks on <span> (x), close the modal
    // // tslint:disable-next-line: only-arrow-functions
    // span.addEventListener('click', function() { modal.style.display = 'none'; }, true);
   }
}
