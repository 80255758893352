import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ContactPageComponent } from './components/contact-page/contact-page.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesPageComponent } from './components/services-page/services-page.component';
import { DownloadsPageComponent } from './components/downloads-page/downloads-page.component';
import { GalleryPageComponent } from './components/gallery-page/gallery-page.component';
import { PolicyPageComponent } from './components/about/policy-page/policy-page.component';
import { VisionPageComponent } from './components/about/vision-page/vision-page.component';
import { ProductsComponent } from './components/products/products.component';
import { CrystalGypsumPageComponent } from './components/products/crystal-gypsum-page/crystal-gypsum-page.component';
import { GypsumPowderPageComponent } from './components/products/gypsum-powder-page/gypsum-powder-page.component';
import { GypsumRockPageComponent } from './components/products/gypsum-rock-page/gypsum-rock-page.component';
import { Mesh100PageComponent } from './components/products/gypsum-powder-page/mesh100-page/mesh100-page.component';
import { Mesh200PageComponent } from './components/products/gypsum-powder-page/mesh200-page/mesh200-page.component';
import { ServicesComponent } from './components/services/services.component';
import { LogesticPageComponent } from './components/services/logestic-page/logestic-page.component';
import { PortPageComponent } from './components/services/port-page/port-page.component';
import { MarinPageComponent } from './components/services/marin-page/marin-page.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { BitumenPageComponent } from './components/products/bitumen-page/bitumen-page.component';
import { PenetrationPageComponent } from './components/products/bitumen-page/penetration-page/penetration-page.component';
import { EmulsionsPageComponent } from './components/products/bitumen-page/emulsions-page/emulsions-page.component';
import { CutbackPageComponent } from './components/products/bitumen-page/cutback-page/cutback-page.component';
import { PgPageComponent } from './components/products/bitumen-page/pg-page/pg-page.component';
import { ViscosityGradePageComponent } from './components/products/bitumen-page/viscosity-grade-page/viscosity-grade-page.component';
import { Penetration4050PageComponent } from './components/products/bitumen-page/penetration-page/penetration4050-page/penetration4050-page.component';
import { Penetration85100PageComponent } from './components/products/bitumen-page/penetration-page/penetration85100-page/penetration85100-page.component';
import { Penetration80100PageComponent } from './components/products/bitumen-page/penetration-page/penetration80100-page/penetration80100-page.component';
import { Penetration6070PageComponent } from './components/products/bitumen-page/penetration-page/penetration6070-page/penetration6070-page.component';
import { Penetration100120PageComponent } from './components/products/bitumen-page/penetration-page/penetration100120-page/penetration100120-page.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent, children: [
    {path: 'policy', component: PolicyPageComponent},
    {path: 'vision', component: VisionPageComponent}
  ]},
  {path: 'products', component: ProductsComponent, children: [
    {path: 'crystal', component: CrystalGypsumPageComponent},
    {path: 'powder', component: GypsumPowderPageComponent},
    {path: 'powder/100', component: Mesh100PageComponent},
    {path: 'powder/200', component: Mesh200PageComponent},
    {path: 'bitumen', component: BitumenPageComponent},
    {path: 'bitumen/penetration', component: PenetrationPageComponent, },
    {path: 'bitumen/penetration/4050', component: Penetration4050PageComponent},
    {path: 'bitumen/penetration/6070', component: Penetration6070PageComponent},
    {path: 'bitumen/penetration/80100', component: Penetration80100PageComponent},
    {path: 'bitumen/penetration/85100', component: Penetration85100PageComponent},
    {path: 'bitumen/penetration/100120', component: Penetration100120PageComponent},
    {path: 'bitumen/viscosity', component: ViscosityGradePageComponent},
    {path: 'bitumen/pg', component: PgPageComponent},
    {path: 'bitumen/cutback', component: CutbackPageComponent},
    {path: 'bitumen/emulsions', component: EmulsionsPageComponent},
    {path: 'rock', component: GypsumRockPageComponent}
  ]},
  {path: 'services', component: ServicesComponent, children: [
    {path: 'logestic', component: LogesticPageComponent},
    {path: 'port', component: PortPageComponent},
    {path: 'marin', component: MarinPageComponent}
  ]},
  {path: 'downloads', component: DownloadsPageComponent},
  {path: 'gallery', component: GalleryPageComponent},
  {path: 'contact', component: ContactPageComponent},
  {path: 'search/:result', component: SearchResultComponent},
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 250]
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }