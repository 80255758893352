import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bitumen-page',
  templateUrl: './bitumen-page.component.html',
  styleUrls: ['./bitumen-page.component.scss']
})
export class BitumenPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
