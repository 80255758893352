import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactPageComponent } from './components/contact-page/contact-page.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesPageComponent } from './components/services-page/services-page.component';
import { DownloadsPageComponent } from './components/downloads-page/downloads-page.component';
import { GalleryPageComponent } from './components/gallery-page/gallery-page.component';
import { CarouselComponent } from './components/home/carousel/carousel.component';
import { ProductsSectionComponent } from './components/home/products-section/products-section.component';
import { SpecialistSectionComponent } from './components/home/specialist-section/specialist-section.component';
import { VisionPageComponent } from './components/about/vision-page/vision-page.component';
import { PolicyPageComponent } from './components/about/policy-page/policy-page.component';
import { ProductsComponent } from './components/products/products.component';
import { CrystalGypsumPageComponent } from './components/products/crystal-gypsum-page/crystal-gypsum-page.component';
import { GypsumPowderPageComponent } from './components/products/gypsum-powder-page/gypsum-powder-page.component';
import { GypsumRockPageComponent } from './components/products/gypsum-rock-page/gypsum-rock-page.component';
import { Mesh100PageComponent } from './components/products/gypsum-powder-page/mesh100-page/mesh100-page.component';
import { Mesh200PageComponent } from './components/products/gypsum-powder-page/mesh200-page/mesh200-page.component';
import { LightboxModule } from 'ngx-lightbox';
import { LogesticPageComponent } from './components/services/logestic-page/logestic-page.component';
import { PortPageComponent } from './components/services/port-page/port-page.component';
import { MarinPageComponent } from './components/services/marin-page/marin-page.component';
import { ServicesComponent } from './components/services/services.component';
import { ServiceSectionComponent } from './components/home/service-section/service-section.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BitumenComponent } from './components/products/bitumen/bitumen.component';
import { BitumenPageComponent } from './components/products/bitumen-page/bitumen-page.component';
// import { FakeBitumenPageComponent } from './components/products/bitumen-page/penetration-page/penetration-page.component';
// import { FakeBitumenPage4050Component } from './components/products/bitumen-page/penetrationPage/penetration4050-page/penetration4050-page.component';
import { PenetrationPageComponent } from './components/products/bitumen-page/penetration-page/penetration-page.component';
import { ViscosityGradePageComponent } from './components/products/bitumen-page/viscosity-grade-page/viscosity-grade-page.component';
import { PgPageComponent } from './components/products/bitumen-page/pg-page/pg-page.component';
import { CutbackPageComponent } from './components/products/bitumen-page/cutback-page/cutback-page.component';
import { EmulsionsPageComponent } from './components/products/bitumen-page/emulsions-page/emulsions-page.component';
import { Penetration4050PageComponent } from './components/products/bitumen-page/penetration-page/penetration4050-page/penetration4050-page.component';
import { Penetration6070PageComponent } from './components/products/bitumen-page/penetration-page/penetration6070-page/penetration6070-page.component';
import { Penetration80100PageComponent } from './components/products/bitumen-page/penetration-page/penetration80100-page/penetration80100-page.component';
import { Penetration85100PageComponent } from './components/products/bitumen-page/penetration-page/penetration85100-page/penetration85100-page.component';
import { Penetration100120PageComponent } from './components/products/bitumen-page/penetration-page/penetration100120-page/penetration100120-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContactPageComponent,
    AboutComponent,
    ServicesPageComponent,
    DownloadsPageComponent,
    GalleryPageComponent,
    CarouselComponent,
    ProductsSectionComponent,
    SpecialistSectionComponent,
    VisionPageComponent,
    PolicyPageComponent,
    ProductsComponent,
    CrystalGypsumPageComponent,
    GypsumPowderPageComponent,
    GypsumRockPageComponent,
    Mesh100PageComponent,
    Mesh200PageComponent,
    LogesticPageComponent,
    PortPageComponent,
    MarinPageComponent,
    ServicesComponent,
    ServiceSectionComponent,
    SearchFilterPipe,
    SearchResultComponent,
    BitumenComponent,
    BitumenPageComponent,
    PenetrationPageComponent,
    ViscosityGradePageComponent,
    PgPageComponent,
    CutbackPageComponent,
    EmulsionsPageComponent,
    Penetration4050PageComponent,
    Penetration6070PageComponent,
    Penetration80100PageComponent,
    Penetration85100PageComponent,
    Penetration100120PageComponent,
    // FakeBitumenPageComponent,
    // FakeBitumenPage4050Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LightboxModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
