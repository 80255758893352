import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-page',
  templateUrl: './policy-page.component.html',
  styleUrls: ['./policy-page.component.scss']
})
export class PolicyPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // ( () => {
    //   'use strict';
    //   // Page is loaded
    //   const objects = document.getElementsByClassName('asyncImage');

    //   Array.from(objects).map( (item) => {
    //     // Start loading image
    //     const img = new Image();
    //     img.src = item.dataset.src;
    //     // Once image is loaded replace the src of the HTML element
    //     img.onload = () => {
    //       item.classList.remove('asyncImage');
    //       return item.nodeName === 'IMG' ?
    //         item.src = item.dataset.src :
    //         item.style.backgroundImage = `url(${item.dataset.src})`;
    //     };
    //   });
    // }) ();
    // var images = new Array();
    // function preload() {
    //   for (i = 0; i < preload.arguments.length; i++) {
    //     images[i] = new Image();
    //     images[i].src = preload.arguments[i];
    //   }
    // }

    // preload("../../../lgyp/assets/images/aboutUs_slide.jpg");
  }
}
